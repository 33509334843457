import authModule from "@/store/modules/auth";
import {ChatInterfaceInfo} from "@/store/apiPayloadsFiles/payloadsInfo";

const AICahtBotApiBaseUrl = process.env.VUE_APP_API_CHAT_BOT_URL;
// @ts-ignore
const token = await authModule.state.token;

const authoptions = token === null ? {
    headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        'Referrer-Policy': 'no-referrer-when-downgrade',
    }
} : {
    headers: {
        'Accept': '*/*',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Referrer-Policy': 'no-referrer-when-downgrade'
    },
    withCredentials: true
}

export const individualAIChat = (chatInterfaceInfo: ChatInterfaceInfo) => {
    return {
        url: `${AICahtBotApiBaseUrl}/individual_conversation`,
        method: 'post',
        options: authoptions,
        payload: { ...chatInterfaceInfo }
    };
}

export const expertAIChat = (chatInterfaceInfo: ChatInterfaceInfo) => {
    return {
        url: `${AICahtBotApiBaseUrl}/experts_conversation`,
        method: 'post',
        options: authoptions,
        payload: { ...chatInterfaceInfo }
    };
}